import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import BlockTextBig from "../components/BlockTextBig/BlockTextBig"
import BlockFullWidthImg from "../components/BlockFullWidthImg/BlockFullWidthImg"
import BlockImageMargin from "../components/BlockImageMargin/BlockImageMargin"
import BlockVideoMargin from "../components/BlockVideoMargin/BlockVideoMargin"
import BlockLeftTitleText from "../components/BlockLeftTitleText/BlockLeftTitleText"
import BlockTextImageLeft from "../components/BlockTextImageLeft/BlockTextImageLeft"
import BlockTextImageRight from "../components/BlockTextImageRight/BlockTextImageRight"
import BlockLeftTitleTextSmall from "../components/BlockLeftTitleTextSmall"
import BlockTwoColumns from "../components/BlockTwoColumns/BlockTwoColumns"
import BlockContact from "../components/BlockContact/BlockContact"
import Footer from "../components/Footer/Footer"
import privacity from "./image-privacity.jpg"

import { useHomePage } from "../hooks/use-homePage"

const Layout = () => {
  const homeData = useHomePage()
  const leftTitleTextFirstData = {
    title: homeData.field_lefttitletext_first_title_,
    text: homeData.field_lefttitletext_first_text_,
  }
  const leftTitleTextSecondData = {
    title: homeData.field_lefttitletext_second_title,
    text: homeData.field_lefttitletext_second_text_,
  }
  const leftTitleTextThirdData = {
    title: homeData.field_lefttitletext_third_title_,
    text: homeData.field_lefttitletext_third_text_,
  }
  const twoColumnsData = {
    titleOne: homeData.field_two_columns_col_1_title_,
    textOne: homeData.field_two_columns_col_1_text_,
    titleTwo: homeData.field_two_columns_col_2_title_,
    textTwo: homeData.field_two_columns_col_2_text_,
  }
  const textImageRight = {
    title: homeData.field_text_image_right_title_,
    text: homeData.field_text_image_right_text_,
    relationships: homeData.relationships,
  }
  const textImageLeft = {
    title: homeData.field_text_image_left_title_,
    text: homeData.field_text_image_left_text_,
    relationships: homeData.relationships,
  }
  const fullWidthImgData = {
    img: homeData.relationships.field_full_width_img,
  }
  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <div className="blockImgCenterDown">
          <div className="blockImgCenterDown__img">
            <img src={privacity} />
          </div>
        </div>
        <div className="blockImgUpText">
          <div className="blockImgUpText__text">
            <h1>Customer Data Privacy Policy</h1>
            <p>
              This Customer Data Privacy Policy supplements privacy and
              confidential information obligations stated in Smartempo LLC
              Master Services Agreement.
            </p>
            <p>
              Customer Data is data that resides on Smartempo LLC, customer, or
              third party systems to which Smartempo LLC is provided access for
              providing Digital Marketing Services. As such, the data may
              include personal information about the Customers’ employees,
              customers, partners or suppliers. Smartempo LLC websites may query
              a User’s browser to see if there are “cookies” previously sent by
              the Smartempo LLC system. In these cases, Smartempo LLC may
              receive User information that could include network routing
              information, equipment information, IP address, and date and time.
              Smartempo LLC also may use cookies to collect general usage and
              volume statistical information. The ma jority of any such cookies
              are set to expire within 30 days.
            </p>
            <p>
              Smartempo LLC may access, collect and/or use Customer Data for the
              following purposes: To fulfill the requirements and activities
              specified in the Customer’s Master Services Agreement and related
              Order Forms and Statements of Work. Technical staff may obtain
              periodic access to Customer Data to administer customer systems,
              test systems, develop, and implement changes. Any temporary copies
              of Customer Data created as a necessary part of this process are
              maintained for a time period relevant to those purposes. In
              accordance with the terms of Master Services Agreements and/or
              with notice to Customers, Smartempo LLC may access and/or use a
              copy of the Customer’s test, development or production
              environment, including Customer Data, to test such new
              configurations, versions, patches, updates, fixes, and validate
              that they work to required specifications.
            </p>
            <p>
              If Smartempo LLC hires subcontractors to assist in providing
              services, their access to Customer Data will be consistent with
              the terms of the Customer’s Master Services Agreement and this
              privacy policy.
            </p>
            <p>
              Smartempo LLC does not use Customer Data except as stated above.
              Smartempo LLC may process Customer Data, but does not control its
              Customers’ information collection or use practices for Customer
              Data. If a Customer provides any Customer Data to Smartempo LLC,
              the Customer is solely responsible for providing any notices
              and/or obtaining any consents necessary for Smartempo LLC to
              access and use Customer Data as specified in this policy and the
              Customer’s Master Services Agreement.
            </p>
            <p>
              Smartempo LLC retains the right to update this policy from time to
              time. We will post a prominent notice in this section of this
              policy notifying Users when it is updated. If we are going to use
              Customer Data in a manner different from that stated at the time
              of collection, we will notify our Customers via email, and they
              will have a choice as to whether or not Smartempo LLC uses the
              Customer Data in this different manner.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
